import unleashBootstrap from '@server/feature-flags/unleash-bootstrap.json';
import type { FeatureFlags, FeatureFlagsKey } from './types';

export const FEATURE_FLAGS = {
	ELEMONOPY_TEST: 'ElemenopyTest',
	STOREFRONT_AVAILABILITY: 'tk-storefront-availability',
	LISTINGS_PRICING: 'tk-listings-pricing',
	STOREFRONT_DETAILED_PRICING: 'tk-storefront-detailed-pricing',
} as const;

export const getInitialState = (): FeatureFlags =>
	Object.values(FEATURE_FLAGS)?.reduce(
		(
			result: Record<FeatureFlagsKey, boolean>,
			featureFlag: FeatureFlagsKey,
		) => {
			result[featureFlag] =
				unleashBootstrap.features.find((f) => f.name === featureFlag)
					?.enabled || false;
			return result;
		},
		{} as FeatureFlags,
	) || {
		ElemenopyTest: false,
		'tk-storefront-availability': false,
		'tk-listings-pricing': false,
		'tk-storefront-detailed-pricing': false,
	};
