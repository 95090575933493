import { getReviewsForGallery, pageAfterFirst } from '@api/reviews';
import honeybadger from '@honeybadger-io/js';
import { atom } from 'jotai';
import { Gallery, Review } from 'types/reviews';

type State = {
	gallery: Gallery[];
	index: number;
	isOpen: boolean;
	modalType: 'overview' | 'details';
	secondaryText?: string[];
};

type OpenLightbox = {
	gallery: Gallery[];
	modalType: 'overview' | 'details';
	index?: number;
	secondaryText?: string[];
};

interface NavigationChange {
	value: number;
	callback?: () => void;
}

const initialState: State = {
	gallery: [],
	index: 0,
	isOpen: false,
	secondaryText: [],
	modalType: 'overview',
};

export const isReviewsLightboxOpenAtom = atom<State['isOpen']>(
	initialState.isOpen,
);
export const reviewsLightboxGalleryAtom = atom<State['gallery']>(
	initialState.gallery,
);
export const reviewsLightboxIndexAtom = atom<State['index']>(
	initialState.index,
);
export const reviewsLightboxModalTypeAtom = atom<State['modalType']>(
	initialState.modalType,
);
export const reviewslightboxSecondaryTextAtom = atom(
	initialState.secondaryText,
);

export const openReviewsLightboxAtom = atom<null, OpenLightbox[], void>(
	null,
	(_get, set, value) => {
		set(isReviewsLightboxOpenAtom, true);
		set(reviewsLightboxGalleryAtom, value.gallery);
		set(
			reviewsLightboxIndexAtom,
			value.index && value.index > -1 && value.index < value.gallery?.length
				? value.index
				: initialState.index,
		);
		set(reviewsLightboxModalTypeAtom, value.modalType);
		set(
			reviewslightboxSecondaryTextAtom,
			value.secondaryText || initialState.secondaryText,
		);
	},
);

export const closeReviewsLightboxAtom = atom(null, (_get, set) => {
	set(isReviewsLightboxOpenAtom, initialState.isOpen);
	set(reviewsLightboxGalleryAtom, initialState.gallery);
	set(reviewsLightboxIndexAtom, initialState.index);
	set(reviewsLightboxModalTypeAtom, initialState.modalType);
	set(reviewslightboxSecondaryTextAtom, initialState.secondaryText);
});

export const reviewsLightboxLandscapeAtom = atom((get) => {
	const index = get(reviewsLightboxIndexAtom);
	const gallery = get(reviewsLightboxGalleryAtom);
	const galleryItem: Gallery | null = gallery.length ? gallery[index] : null;

	if (!galleryItem) {
		return 'landscape';
	}

	const height = 'height' in galleryItem ? Number(galleryItem.height) : 0;
	const width = 'width' in galleryItem ? Number(galleryItem.width) : 0;

	if (height === 0 || width === 0) {
		return 'unknown';
	}
	return width >= height ? 'landscape' : 'portrait';
});

export const reviewsLightboxNavigationChange = atom<
	null,
	NavigationChange[],
	void
>(null, (get, set, { value, callback }) => {
	const index = get(reviewsLightboxIndexAtom);
	if (index !== value) {
		set(reviewsLightboxIndexAtom, value);
		if (callback) {
			callback();
		}
	}
});

export const reviewsLightboxNextImageAtom = atom<
	null,
	((() => void) | undefined)[],
	void
>(null, (get, set, callback) => {
	const index = get(reviewsLightboxIndexAtom);
	const gallery = get(reviewsLightboxGalleryAtom);

	if (index < gallery.length - 1) {
		set(reviewsLightboxNavigationChange, {
			value: index + 1,
			callback,
		});
	}
});

export const reviewsLightboxPrevImageAtom = atom<
	null,
	((() => void) | undefined)[],
	void
>(null, (get, set, callback) => {
	const index = get(reviewsLightboxIndexAtom);

	if (index > 0) {
		set(reviewsLightboxNavigationChange, {
			value: index - 1,
			callback,
		});
	}
});

export const galleryReviewsPageAtom = atom(1);
export const hasNextPageAtom = atom(true);
export const isFetchingGalleryReviewsAtom = atom(false);
export const galleryReviewsAtom = atom<Review[]>([]);
export const galleryToReviewMapAtom = atom((get) => {
	const galleryReviews = get(galleryReviewsAtom);
	return galleryReviews.reduce(
		(acc, cur) => {
			cur.gallery
				.map((item) => [item.id, cur.id])
				.forEach(([key, value]) => {
					acc[key] = value;
				});
			return acc;
		},
		{} as Record<string, string>,
	);
});

export const currentGalleryReviewIndexAtom = atom((get) => {
	const galleryReviews = get(galleryReviewsAtom);
	const galleryToReviewMap = get(galleryToReviewMapAtom);
	const index = get(reviewsLightboxIndexAtom);
	const galleryItems = get(reviewsLightboxGalleryAtom);
	return galleryReviews.findIndex(
		(r) => r.id === galleryToReviewMap[galleryItems[index]?.id],
	);
});

export const fetchNextGalleryReviewsForVendorAtom = atom(
	null,
	async (get, set, vendorId: string) => {
		const isFetching = get(isFetchingGalleryReviewsAtom);
		const hasNextPage = get(hasNextPageAtom);
		const galleryReviews = get(galleryReviewsAtom);
		const currentGalleryReviewIndex = get(currentGalleryReviewIndexAtom);
		const reviewIsInGallery = currentGalleryReviewIndex > -1;
		const shouldFetchNextPage =
			!reviewIsInGallery ||
			currentGalleryReviewIndex + 5 > galleryReviews.length;
		if (isFetching || !hasNextPage || !shouldFetchNextPage) return;
		const page = get(galleryReviewsPageAtom);
		try {
			set(isFetchingGalleryReviewsAtom, true);
			const result = await getReviewsForGallery(vendorId, page).then((r) =>
				r.json(),
			);
			set(galleryReviewsAtom, (reviews) =>
				page === 1 ? result.data : [...reviews, ...result.data],
			);
			set(galleryReviewsPageAtom, (prev) =>
				prev === 1 ? pageAfterFirst : prev + 1,
			);
			const fetchedReviewsCount =
				page === 1
					? result.data.length
					: galleryReviews.length + result.data.length;
			set(hasNextPageAtom, result.totalCount > fetchedReviewsCount);
		} catch (e) {
			honeybadger.notify('Fetch gallery review failed', e);
		} finally {
			set(isFetchingGalleryReviewsAtom, false);
		}
	},
);
