import { newHeartAssignmentSelector } from '@redux/experiments/selectors/new-heart';
import { useAppSelector } from '@redux/hooks';
import { vendorManagerUrl } from '@settings';
import { Ref, RefObject, useCallback, useEffect, useMemo } from 'react';
import { useSourcePage } from './use-source-page';
import useAnalyticsContext from './useAnalyticsContext';

const inboxSelector = '[title="Vendor conversation inbox"]';
const favoritesSelector = '[title="Vendor manager"]';

function waitForInboxNodes(root: HTMLElement) {
	return new Promise<NodeListOf<HTMLElement>>((resolve) => {
		const existingInboxNodes = root.querySelectorAll(
			inboxSelector,
		) as NodeListOf<HTMLElement>;
		if (existingInboxNodes.length > 0) {
			resolve(existingInboxNodes);
			return;
		}
		new MutationObserver((mutationRecords, observer) => {
			const inboxNodes = root.querySelectorAll(
				inboxSelector,
			) as NodeListOf<HTMLElement>;
			if (inboxNodes.length > 0) {
				resolve(inboxNodes);
				observer.disconnect();
			}
		}).observe(root, {
			childList: true,
			subtree: true,
		});
	});
}

function injectFavoritesLink(root: HTMLElement, track: () => void) {
	waitForInboxNodes(root).then((inboxNodes) => {
		for (const inboxNode of inboxNodes) {
			const navLinks = inboxNode.parentNode;
			if (!navLinks) return;
			const favoritesNode = createFavoritesNode(inboxNode, track);
			navLinks.insertBefore(favoritesNode, inboxNode);
		}
	});
}

function removeFavoritesLink(root: HTMLElement) {
	const favoritesNodes = root.querySelectorAll(favoritesSelector);
	for (const favoritesNode of favoritesNodes) {
		favoritesNode.remove();
	}
}

function createHeartSvg() {
	const heartSvg = document.createElementNS(
		'http://www.w3.org/2000/svg',
		'svg',
	);
	heartSvg.setAttribute('width', '24');
	heartSvg.setAttribute('height', '24');
	heartSvg.setAttribute('viewBox', '0 0 24 24');
	heartSvg.setAttribute('fill', 'none');
	const heartPath = document.createElementNS(
		'http://www.w3.org/2000/svg',
		'path',
	);
	heartPath.setAttribute('fill-rule', 'evenodd');
	heartPath.setAttribute('clip-rule', 'evenodd');
	heartPath.setAttribute(
		'd',
		'M12 22C11.8679 21.9973 11.7392 21.958 11.6282 21.8865C4.48075 16.9768 1 12.3868 1 7.84838C0.99998 4.62645 3.60938 2.01136 6.83568 2C8.88841 2.01415 10.8095 3.01176 12 4.68179C13.1928 3.01437 15.1124 2.01752 17.1643 2C18.7148 2.00273 20.2006 2.62044 21.295 3.71722C22.3894 4.814 23.0027 6.30002 23 7.84838C23 12.3868 19.5296 16.9768 12.3822 21.8865C12.2679 21.9594 12.1355 21.9987 12 22ZM6.83568 3.33058C4.34928 3.34759 2.34266 5.3653 2.34272 7.84838C2.34272 11.7782 5.59624 16.0382 12 20.5147C18.4141 16.0382 21.6573 11.7782 21.6573 7.84838C21.663 5.36298 19.6531 3.34195 17.1643 3.33058C15.1862 3.34195 13.4059 4.53128 12.6404 6.35276C12.5188 6.59145 12.2733 6.74177 12.0052 6.74177C11.737 6.74177 11.4915 6.59145 11.3699 6.35276C10.6029 4.52784 8.81751 3.33783 6.83568 3.33058',
	);
	heartPath.setAttribute('fill', 'black');
	heartSvg.appendChild(heartPath);
	return heartSvg;
}

function createFavoritesNode(element: HTMLElement, track: () => void) {
	const favoritesNode = element.cloneNode() as HTMLElement;
	favoritesNode.setAttribute('aria-label', 'Vendor manager');
	favoritesNode.setAttribute('href', vendorManagerUrl);
	favoritesNode.setAttribute('title', 'Vendor manager');
	favoritesNode.onclick = () => track();
	favoritesNode.removeAttribute('data-trackable-selection');
	favoritesNode.style.paddingRight = '8px';
	favoritesNode.appendChild(createHeartSvg());
	return favoritesNode;
}

export const useInjectFavoritesNavLink = (
	headerNavRef: RefObject<HTMLDivElement>,
) => {
	const sourcePage = useSourcePage();
	const newHeartAssignment = useAppSelector((state) =>
		newHeartAssignmentSelector(state),
	);
	const shouldInjectFavorites = useMemo(
		() => newHeartAssignment === 'new-heart',
		[newHeartAssignment],
	);
	const { track } = useAnalyticsContext();
	const injectFavoritesNavLink = useCallback(
		() =>
			track({
				event: 'Menu Interaction',
				properties: {
					selection: 'saved_vendor_heart',
					sourceContent: 'universal navigation',
					sourcePage,
				},
			}),
		[track, sourcePage],
	);
	useEffect(() => {
		if (!headerNavRef.current) return;
		if (shouldInjectFavorites) {
			injectFavoritesLink(headerNavRef.current, injectFavoritesNavLink);
		} else {
			removeFavoritesLink(headerNavRef.current);
		}
	}, [shouldInjectFavorites, injectFavoritesNavLink, headerNavRef]);
};
